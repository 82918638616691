$(document).ready(function () {

    /*--Overflow scroll glitch fix---*/
    let div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';

    document.body.append(div);
    let scrollWidth = div.offsetWidth - div.clientWidth;
    div.remove();


    let renderClickableBG = (isDark, elementToClose, id, renderParent = $('body'), blockScroll = true) => {
        renderParent.append(`<div class="clickable-bg" id=${id}></div>`);
        if (blockScroll) {
            $('body').css({
                'padding-right': scrollWidth,
                'overflow-y': 'hidden',

            });
        }
        if (isDark) {
            $('.clickable-bg').addClass('clickable-bg--dark').fadeOut(1).fadeIn(400);
        }
        $(document).on('click', '.clickable-bg', function () {
            $(this).remove();
            if (elementToClose) {
                elementToClose.removeClass('opened');
                if (blockScroll) {
                    $('body').css({
                        'padding-right': 0,
                        'overflow-y': 'auto',
                    });
                }
            }
        })
    }


    // Mobile menu
    const toggleMobileMenu = (mobileMenu) => {
        if (mobileMenu.hasClass('opened')) {
            mobileMenu.removeClass('opened');
            $('body').css({
                'padding-right': 0,
                'overflow-y': 'auto',
            });
        }
        else {
            mobileMenu.addClass('opened');
        }
    }
    $(document).on('click', '.js-open-mobile-menu', function () {
        const mobileMenu = $('.mobile-menu')
        toggleMobileMenu(mobileMenu);
        renderClickableBG(true, mobileMenu, 'mobile-menu-bg')
    })
    $(document).on('click', '.js-close-mobile-menu', function () {
        toggleMobileMenu($('.mobile-menu'));
        $('#mobile-menu-bg').remove();

    })

    const moveElement = (element, target, screenSize, append = true, after = false) => {
        if (screen.width < screenSize) {
            if (after) {
                for (let i = 0; i < $(element).length; i++) {
                    $(target).eq(i).after($(element).eq($(target).length === 1 ? 0 : i))
                }
            } else {
                if (append) {
                    for (let i = 0; i < $(element).length; i++) {
                        $(element).eq(i).appendTo($(target).eq($(target).length === 1 ? 0 : i))
                    }
                } else {
                    for (let i = 0; i < $(element).length; i++) {
                        $(element).eq(i).prependTo($(target).eq($(target).length === 1 ? 0 : i))
                    }
                }
            }
        }
    }

    moveElement('.main-nav', '.mobile-menu__inner', 991);
    moveElement('.header__slogan', '.mobile-menu__inner', 1199);
    moveElement('.header__contacts', '.mobile-menu__inner', 767);

    $('.js-scroll-to').on('click', function () {
        const elementToScroll = '#' + $(this).attr('data-scroll');
        $([document.documentElement, document.body]).animate({
            scrollTop: $(elementToScroll).offset().top
        }, 1000);
    });

    $(document).on('click', '.js-open-modal', function (e) {
        e.preventDefault();
        let modalToOpen = $(this).attr('data-modal');
        $(`#${modalToOpen}`).addClass('opened').fadeOut(1).fadeIn(400);
        $('body').addClass('modal-opened').css('padding-right', scrollWidth);
        renderClickableBG(true, $(`#${modalToOpen}`), $(`#${modalToOpen}`))
    })


    $(document).on('click', '.js-close-modal', function () {
        let modalToClose = $(this).parents('.modal');
        modalToClose.removeClass('opened');
        $('body').removeClass('modal-opened').css('padding-right', 0);
        modalToClose.find(('.clickable-bg')).remove();
    })

});


